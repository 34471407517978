import React from "react";
import { MarginnoteProps } from "./notes";

const Marginnote = ({ note, nodes }: MarginnoteProps) => {
  const noteInputId = Math.floor(Math.random() * 999999);
  return (
    <>
      <label htmlFor={"mn-random-" + noteInputId} className="margin-toggle">
        <sup>&#8853;</sup>
      </label>
      <input
        type="checkbox"
        id={"mn-random-" + noteInputId}
        className="margin-toggle"
      />
      {nodes ? (
        <span className="marginnote">
          {nodes.title}
          {nodes.image}
          {nodes.caption}
        </span>
      ) : (
        <span
          className="marginnote"
          dangerouslySetInnerHTML={{ __html: note }}
        ></span>
      )}
    </>
  );
};

export default Marginnote;
