import React from "react";
import { MDXProvider } from "@mdx-js/react";
import Sidenote from "../components/sidenote";
import Marginnote from "../components/marginnote";
import "./layout.module.scss";

const shortcodes = { Sidenote, Marginnote };

export default function Layout({ children }) {
  return (
    <div className="layout">
      <MDXProvider components={shortcodes}>{children}</MDXProvider>
    </div>
  );
}
